import React from 'react'
import Footer from '../components/basic/footer'
import NavBar from '../components/basic/nav-bar'
import Breadcrumb from '../components/basic/breadcrumb'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from "gatsby-background-image"
import Seo from '../components/basic/seo'
import Galerybarcelona from '../components/barcelona/galery'
import SelectBuild from '../components/barcelona/selectbuild'
import SelectProject from '../components/barcelona/selectproject'

export default function Barcelona() {
    const data = useStaticQuery(
        graphql`
          query {
            desktop: file(relativePath: { eq: "barcelona_head.jpg" }) {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `
      )
      const imageData = data.desktop.childImageSharp.fluid
  return (
<div className="container-fluid" style={{ backgroundColor: '#edf0f0', padding: '0'}}>
    <Seo title="Barcelona" />
    <BackgroundImage
            Tag="section"
            className="container-fluid"
            fluid={imageData}
            style={{ height: '40vh', backgroundPosition: 'center' }}
        >
        <NavBar />
        <div className="container">
            <div className="row d-flex align-items-center p-5">
                <div className="col">
                    <h1 className="text-light">Portfolio Barcelona</h1>
                    <Breadcrumb />
                </div>
            </div>
        </div>
    </BackgroundImage>
    <div className="content">
      <div className="container p-5 border-bottom">
        <div className="row d-flex align-items-center">
          <div className="col">
            <SelectBuild />
          </div>
          <div className="col">
            <SelectProject />
          </div>
        </div>
      </div>
      <div className="container p-5">
        <Galerybarcelona />
      </div>
    </div>
    <div>
      <Footer />
    </div>
</div>
  )
}