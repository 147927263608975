import React from 'react'
import { Link } from "gatsby"
import { map } from 'lodash'
import barcelonaData from './data'
import MapGoogle from './map'

export default function Galerybarcelona() {
  return (
  <div>
    <div className="clearfix"></div>
    <div className="grid-container">
      <div
        className="itemMapa"
        style={{ position: "relative" }}
      >
        <MapGoogle />
      </div>
        {map(barcelonaData, (build, index) => (
          <div
            className="itemBuild"
            style={{ paddingLeft: "0" }}
            id={build.id}
            name={build.active}
          >
            <Link to={`/app/barcelona/profile/${build.id}`} className="text-decoration-none">
              <div className="card">
                <span className="badge bg-primary text-wrap opacity-75 stick-left">
                  {build.new}
                </span>
                <span className="badge bg-primary text-wrap opacity-75 stick">
                  {build.id}
                </span>
                <img src={build.img} className="card-img-top img-galery" alt="Edificio" />
                <div className="card-body">
                  <p className="text-secondary">
                    <small>{build.address}</small>
                  </p>
                  <h5 className="card-text text-dark">{build.name}</h5>
                  <p
                    className="text-secondary pb-0 mb-0"
                    style={{ fontSize: "12px" }}
                  >
                    <small>
                      <img
                        className={build.hidden3}
                        src={build.imgbream1}
                        alt="Bream"
                      />{" "}
                      {build.bream1}
                    </small>
                    <br />
                    <small>
                      <img
                        className={build.hidden}
                        src={build.imgbream2}
                        alt="Bream"
                      />{" "}
                      {build.bream2}
                    </small>
                    <br />
                    <small>
                      <img
                        className={build.hidden2}
                        src={build.imgbream3}
                        alt="Bream"
                      />{" "}
                      {build.bream3}
                    </small>
                  </p>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    <div className="clearfix"></div>
  </div>
  )
}
